// dashboard
export const superAdmin = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];
export const sanitationDash_param = [
  "1", // super admin
  "2", // Admin
  "3", // Mela Adhikari
  "4", // Additional Mela Adhikari
  "5", // Incharge Sanitation
  "6", // Monitoring Agent (Swachhagrahis)
  "7", // Supervisor Monitoring Agent (Swachhagrahis)
  // "8", // Vendor
  "9", // Sector Medical Officer (SMO)
  "10", // Sub Divisional  Magistrate (SDM)
  "11", // Gram Panchayat/Vikas Adhikari
  "12", // Circle Inspector
  "13", // Vendor Supervisor
  "14", // Nayab Tahsildar / Sector Magistrate
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];
export const tentageDash_param = ["1", "2", "3", "4"]; // vendor view permission added to the navbar
// export const tentageDash_param = ["1", "2", "3", "4", "8"];
export const vehicleDash_param = ["1", "2", "3", "4", "5", "9"]; // vehicle
export const incidentDash_param = ["1", "2", "3", "4"];
export const vendorDash_param = ["8"]; // vendor dashboard
export const SLADash_param = ["1", "2", "3", "4"];

// user access and registration
export const userAccess_param = ["1", "2"];

// master data creation
export const masterData_param = ["1", "2"];

// DMS
export const DMS_param = ["1", "2", "3", "4"];

// Reports
export const reports_param = ["1", "2", "3", "4", "5", "8", "9"];
export const monitoring_reports = ["1", "2", "3", "4", "5", "8"];
export const monitoring_dailyWeekly_reports = ["1", "2", "3", "4", "5"];
export const registration_dailyWeekly_reports = ["1", "2", "3", "4", "5"];
export const sector_wise_reports = ["1", "2", "3", "4", "5", "8"];
export const circle_wise_reports = ["1", "2", "3", "4", "5"];
export const vendor_wise_reports = ["1", "2", "3", "4", "5"];
export const incident_reports = ["1", "2", "3", "4", "5", "8", "9"];
export const inspections_reports = ["1", "2", "3", "4", "5", "9"];
export const gsd_wise_regi_reports = ["1", "2", "3", "4", "5"];
export const gsd_wise_monitoring_reports = ["1", "2", "3", "4", "5", "9"];
export const vehicle_reports = ["1", "2", "3", "4", "5"];
export const vehicle_imei_reports = ["1", "2", "3", "4", "5"];
export const vendor_wise_regi_reports = ["1", "2", "3", "4", "5"];
export const sector_wise_regi_reports = ["1", "2", "3", "4", "5"];
export const sector_type_wise_regi_reports = ["1", "2", "3", "4", "5"];
export const vendor_type_wise_regi_reports = ["1", "2", "3", "4", "5"];
export const parking_type_wise_regi_reports = ["1", "2", "3", "4", "5"];
export const parking_wise_monitoring_reports = ["1", "2", "3", "4", "5"];
export const attendance_reports = ["1", "2", "3", "4", "5", "9"];
export const asset_incident_reports = ["1", "2", "3", "4", "5"];

// asset type delete and update permission
export const asset_delete_permisssion = ["1", "2"];
